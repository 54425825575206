import React, { useState, useEffect } from 'react';
import './Recipe.css';
import '../App.css';
import { useParams, useLocation } from 'react-router-dom';
import SearchBox from '../components/SearchBox';
import RecipeCard from '../components/RecipeCard';
import ChatBox from '../components/ChatBox';
import RecommendedRecipes from '../components/RecommendedRecipes';

import CircularProgress from '@mui/material/CircularProgress';
import { captureError } from '../analytics';
import { Helmet } from 'react-helmet';


function Recipe() {
  const { query } = useParams();
  const location = useLocation();
  const canonicalUrl = `https://foodrecipepro.com${location.pathname}`;
  const [recipe, setRecipe] = useState(null);
  const [pageTitle, setPageTitle] = useState("Easy and Customizable Recipes with AI Chat Assistance");
  const [seoKeywords, setSeoKeywords] = useState('recipes, ingredient substitutes, virtual chef, cooking advice, recipe search, food, cuisine, culinary tips, meal planning');
  const [seoDesc, setSeoDesc] = useState('Discover new recipes, find ingredient substitutes, and chat with a virtual chef to get expert cooking advice. Your culinary journey starts here!');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [popularSearchTerms, setPopularSearchTerms] = useState([]);
  const [recommendedRecipes, setRecommendedRecipe] = useState([]);

  useEffect(() => {
    async function loadPopularSearchTerms() {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getPopularSearchTerms`);
      const popularSearchTerms = await response.json();
      setPopularSearchTerms(popularSearchTerms);
    }

    loadPopularSearchTerms();
  }, []);
  
  useEffect(() => {
    function getRandomItems(arr, numItems) {
      const indexes = new Set();
      const randomItems = [];
    
      const itemsToSelect = Math.min(numItems, arr.length);

      while (indexes.size < itemsToSelect) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        if (!indexes.has(randomIndex)) {
          indexes.add(randomIndex);
          randomItems.push(arr[randomIndex]);
        }
      }
    
      return randomItems;
    }
    const recosNames = getRandomItems(popularSearchTerms, 8);
    const recoRecipes = [];
    for (let index = 0; index < recosNames.length; index++) {
      const recipe = {
        name: recosNames[index]
      }
      recoRecipes.push(recipe);
    }
    
    setRecommendedRecipe(recoRecipes);
    
  }, [popularSearchTerms])

  useEffect(() => {
    if (query) {
      searchRecipe(query);
    }
  }, [query]);

  const searchRecipe = async (query) => {
    setLoading(true);
    setRecipe(null);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/searchRecipe?query=${query}`);
      if (response.ok) {
        const data = await response.json();
        setRecipe(data);
      } else {
        setError('Error fetching recipe');
        captureError(`Error fetching recipe: ${response.statusText}`);
        //console.error('Error fetching recipe:', response.statusText);
      }
    } catch (error) {
      setError('Error fetching recipe');
      captureError(error);
      //console.error('Error fetching recipe:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (recipe) {
      setPageTitle("Tasty & Customizable Recipes with AI Chat Assistance - " + recipe.name);
      
      if (recipe.seoDesc) {
        setSeoDesc(recipe.seoDesc);
      } else if (recipe.description) {
        setSeoDesc(recipe.description);
      }
      
      if (recipe.keywords) {
        setSeoKeywords(recipe.keywords);
      }
    }
  }, [recipe]);

  return (
    <div className="App recipe-background">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={seoDesc} />
        <meta name="keywords" content={seoKeywords} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <SearchBox query={query} popularSearchTerms={popularSearchTerms} />

      {error && <p className="search-error">{error}</p>}
      {loading ? (
        <div className="loading-indicator">
          <CircularProgress />
        </div>
      ) : (
        <>
          {recipe && <RecipeCard recipe={recipe} />}
          {recipe && <ChatBox dishName={recipe.name} />}
          <RecommendedRecipes recipes={recommendedRecipes} />
        </>
      )}
    </div>
  );
}

export default Recipe;
