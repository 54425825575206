import React, { useState } from 'react';
import axios from 'axios';
import './ChatBox.css';
import SendIcon from '@mui/icons-material/Send';
import Message from './Message';
import { trackEvent, captureError } from '../analytics';


function ChatBox({ dishName }) {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [isAssistantTyping, setIsAssistantTyping] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userMessage = inputValue.trim();
    if (!userMessage || isSending) return;
    setIsSending(true);
    trackEvent('chat_message_sent', {
      message_content: userMessage,
      message_length: userMessage.length,
    });


    const updatedMessages = [
      ...messages,
      { role: 'user', content: userMessage, timestamp: new Date().toISOString() },
    ];

    setMessages(updatedMessages);
    setInputValue('');

    setIsAssistantTyping(true); // Show typing animation
    // Send only the last X messages to the API
    const numberOfMessagesToSend = 9; // Change this value as needed
    const messagesToSend = updatedMessages.slice(-numberOfMessagesToSend);

    const requestBody = {
      dish: dishName,
      chatMessages: messagesToSend,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/handleMessage`,
        requestBody
      );
      setError(null); // Clear any previous error
      const newMessages = [...updatedMessages, response.data];
      setMessages(newMessages);
      const responseTime = new Date().getTime() - new Date(messagesToSend[messagesToSend.length - 1].timestamp).getTime();
      trackEvent('chat_message_received', {
        message_content: response.data.content,
        message_length: response.data.content.length,
        response_time: responseTime,
      });

    } catch (error) {
      captureError(error);
      setError('An error occurred. Please try again.'); // Set the error state
      //console.error('Error handling message:', error);

      // Add the error message as a chat message from the "assistant"
      /*const errorResponse = {
        role: 'assistant',
        content: 'An error occurred. Please try again.',
        timestamp: new Date().toISOString(),
        error: true
      };
      setMessages([...updatedMessages, errorResponse]);*/
    } finally {
      setIsAssistantTyping(false); // Hide typing animation
      setIsSending(false);
    }
  };

  //console.log('msgs', messages);
  return (
    <div className="chat-container">
      <div className="chat-box">
        <div className="chat-title">
          <h2>Chat with our AI Chef</h2>
        </div>

        {error && <div className="error-message">{error}</div>}
        <div className="chat-messages">
          {
            messages && messages.map((message, index) => (
              <Message
                key={index}
                sender={message.role}
                content={message.content}
                isUser={message.role === "user"}
                isError={message.error}
              />
            ))
          }
          {isAssistantTyping && (
            <div className="chat-message assistant">
              <div className="typing-animation">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit} className="chat-input-form">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            className="chat-input"
            placeholder="Type your message here..."
          />
          <button type="submit" className="send-btn" disabled={isSending}>
            <SendIcon />
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChatBox;
