import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import usePageViewTracking from './hooks/usePageViewTracking';
import Homepage from './pages/Homepage';
import Recipe from './pages/Recipe';

function AppContent() {
  usePageViewTracking(); // Call the custom hook to track page views

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/:query" element={<Recipe />} />
    </Routes>
  );
}

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <AppContent />
      </div>
    </BrowserRouter>
  );
}

export default App;
