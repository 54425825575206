import {app} from './firebase';
import { getAnalytics, logEvent } from "firebase/analytics";

import * as Sentry from "@sentry/react";

const analytics = getAnalytics(app);


const trackEvent = (eventName, params) => {
  logEvent(analytics, eventName, params);
};

const initSentry = () => Sentry.init({
  dsn: "https://8d5c4a30d0da44729649803936970d6a@o272966.ingest.sentry.io/4505137417093120",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const captureError = (error, extraData = {}, tags = {}) => {
  Sentry.withScope((scope) => {
    // Add extra data
    for (const key in extraData) {
      scope.setExtra(key, extraData[key]);
    }

    // Add tags
    for (const key in tags) {
      scope.setTag(key, tags[key]);
    }

    // Capture the exception
    Sentry.captureException(error);
  });
};


export { trackEvent, initSentry, captureError };
