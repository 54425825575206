import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './Homepage.css';

function Homepage() {
  const articles = [
    { title: 'Baking Recipes', url: '/articles/baking-recipes.html' },
    { title: 'Breakfast Recipes', url: '/articles/breakfast-recipes.html' },
    { title: 'Chicken Recipes', url: '/articles/chicken-recipes.html' },
    { title: 'Budget Friendly Meals', url: '/articles/budget-friendly-meals.html' },
    { title: 'Dessert Recipes', url: '/articles/dessert-recipes.html' },
    { title: 'Dinner Ideas', url: '/articles/dinner-ideas.html' },
    { title: 'Family Friendly Recipes', url: '/articles/family-friendly-recipes.html' },
    { title: 'Gluten Free Recipes', url: '/articles/gluten-free-recipes.html' },
    { title: 'Healthy Meals', url: '/articles/healthy-meals.html' },
    { title: 'High Protein Meals', url: '/articles/high-protein-meals.html' },
    { title: 'Holiday Recipes', url: '/articles/holiday-recipes.html' },
    { title: 'Instant Pot Recipes', url: '/articles/instant-pot-recipes.html' },
    { title: 'Low Carb Recipes', url: '/articles/low-carb-recipes.html' },
    { title: 'Meal Prep Ideas', url: '/articles/meal-prep-ideas.html' },
    { title: 'Quick And Easy Recipes', url: '/articles/quick-and-easy-recipes.html' },
    { title: 'Quick Recipes', url: '/articles/quick-recipes.html' },
    { title: 'Recipes For Kids', url: '/articles/recipes-for-kids.html' },
    { title: 'Seafood Recipes', url: '/articles/seafood-recipes.html' },
    { title: 'Slow Cooker Recipes', url: '/articles/slow-cooker-recipes.html' },
    { title: 'Vegan Recipes', url: '/articles/vegan-recipes.html' },
    { title: 'Vegetarian Recipes', url: '/articles/vegetarian-recipes.html' }
  ];


  const navigate = useNavigate();
  const location = useLocation();
  const canonicalUrl = `https://foodrecipepro.com${location.pathname}`;


  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    if (searchTerm) {
      // Redirect to the search result page
      // Replace 'your-search-page' with the appropriate route
      navigate(`/${searchTerm}`);
    }
  };


  const recipeCategories = [
    {
      title: 'Appetizers',
      examples: [
        { name: 'Bruschetta', image: 'https://replicate.delivery/pbxt/kbAdnScraSIuKNdIYdijlZseeGJ3EphefDNq7eT3o2AmWuhHC/out-3.png' },
        { name: 'Spring Rolls', image: 'https://replicate.delivery/pbxt/N6bj2X8NPs4YPRgRuKIVqIadN1fMEzDmWWemlIBbdOSpyN8QA/out-0.png' },
      ],
    },
    {
      title: 'Main Dishes',
      examples: [
        { name: 'Fajitas', image: 'https://replicate.delivery/pbxt/Omjirs3CepRFSSpUMFbngui4wUf3tOulF30BA2wTNhwlUY5QA/out-0.png' },
        { name: 'Spaghetti Carbonara', image: 'https://replicate.delivery/pbxt/tGfiLGw7VW3MAKiHEg3kQI12SuOLeRhSBukyEzVYf4mMlb4hA/out-2.png' },
      ],
    },
    {
      title: 'Drinks',
      examples: [
        { name: 'Margarita', image: 'https://replicate.delivery/pbxt/Ba75Cwicr5pYGhUiclwf6E6krGllw1kJKmE8GCDMCrtNnrcIA/out-1.png' },
        { name: 'Negroni', image: 'https://replicate.delivery/pbxt/xPkrFxPitm7wOV1SbF1zxAIRes3T5vbIJsTxtNIO8UOO5GeQA/out-0.png' },
      ],
    },
    // Add more categories here
  ];

  return (
    <div className="homepage">
      <Helmet>
        <title>Find and customize any recipe</title>
        <meta name="description" content={'Discover new recipes, find ingredient substitutes, and chat with a virtual chef to get expert cooking advice. Your culinary journey starts here!'} />
        <meta name="keywords" content={'recipes, ingredient substitutes, virtual chef, cooking advice, recipe search, food, cuisine, culinary tips, meal planning'} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="content">
        <div className="intro-section">
          <h1>Easily find and customize any recipe </h1>
          <p>Discover a variety of recipes, from appetizers to main dishes and drinks.</p>
        </div>
        <div className="search-container">
          <input
            className="search-input"
            type="text"
            placeholder="Search for a recipe..."
            onChange={handleSearchInputChange}
          />
          <button className="search-button" onClick={handleSearch}>
            Search
          </button>
        </div>
        <div className="recipe-categories">
          {recipeCategories.map((category, index) => (
            <div key={index} className="recipe-category">
              <h2>{category.title}</h2>
              <div className="recipe-grid">
                {category.examples.map((recipe, index) => (
                  <Link key={index} to={`/${recipe.name}`} className="recipe-item">
                    <img src={recipe.image} alt={recipe.name} />
                    <h3>{recipe.name}</h3>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div>
          <div class="articles-header">
            <h2>Articles</h2>
          </div>
          <div className="articles">
            {articles.map((article, index) => (
              <a key={index} href={article.url} target="_blank" rel="noopener noreferrer">
                {article.title}
              </a>
            ))}
          </div>
        </div>
    </div>
    </div >
  );
}

export default Homepage;
