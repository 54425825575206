import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CircularProgress from '@mui/material/CircularProgress';
import './RecipeCard.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


function RecipeCard({ recipe }) {
  const [ingredients, setIngredients] = useState([]);
  const [replacingIngredient, setReplacingIngredient] = useState(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (recipe) {
      setIngredients(recipe.ingredients);
    }
  }, [recipe]);

  const removeInstructionNumbering = (instruction) => {
    return instruction.replace(/^\d+\.\s*/, '');
  };


  const handleReplaceIngredient = async (dish, ingredient) => {
    setReplacingIngredient(ingredient);
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/replaceIngredient?dish=${encodeURIComponent(dish)}&ingredient=${encodeURIComponent(ingredient)}`
    );
    const data = await response.json();
    const newIngredients = data.alternativeIngredient;
    setOptions(newIngredients);
    setOpen(true);
  };

  const handleSelectReplacement = (selected) => {
    const updatedIngredients = ingredients.map((item) =>
      item === replacingIngredient ? selected : item
    );
    setIngredients(updatedIngredients);
    setReplacingIngredient(null);
    setOpen(false);
  };
  const handleClose = () => {
    setReplacingIngredient(null);
    setOpen(false);
  };


  if (!recipe) {
    return <div className="recipe">No recipe found</div>;
  }

  const { instructions, difficulty, prepTime, cookTime } = recipe;

  return (
    <section className="recipe-card">
      <h1 className="recipe-title">{recipe.name}</h1>
      <p className="recipe-description">{recipe.description}</p>

      {recipe.images && (
        <div className="recipe-images">
          <Carousel showArrows infiniteLoop useKeyboardArrows showStatus={false} showThumbs={true} thumbWidth={"20%"}>
            {recipe.images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`${recipe.imagePrompt}`} />
              </div>
            ))}
          </Carousel>
        </div>
      )}

      <div className="recipe-info">
        <p><i className="fas fa-tachometer-alt"></i> Difficulty: {difficulty}</p>
        <p><i className="fas fa-clock"></i> Preparation Time: {prepTime} minutes</p>
        <p><i className="fas fa-hourglass-half"></i> Cook Time: {cookTime} minutes</p>
      </div>

      <div className="recipe-ingredients">
        {replacingIngredient && (
          <div className="loading-indicator">
            <CircularProgress />
          </div>
        )}
        <h2>Ingredients</h2>
        <ul>
          {ingredients.map((ingredient, index) => (
            <li key={index}>
              <div className="ingredient-container">
                {ingredient}
                <IconButton
                  className="replace-button"
                  disabled={replacingIngredient === ingredient}
                  onClick={() => handleReplaceIngredient(recipe.name, ingredient)}
                >
                  <SwapHorizIcon />
                </IconButton>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="recipe-instructions">
        <h2>Instructions</h2>
        <ul style={{ counterReset: 'step' }}>
          {instructions.map((instruction, index) => (
            <li key={index}>{removeInstructionNumbering(instruction)}</li>
          ))}
        </ul>
      </div>
      {recipe.seoDesc && <p className="seo-text">{recipe.seoDesc}</p>}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Choose a replacement for {replacingIngredient}</DialogTitle>
        <DialogContent>
          {options.map((option, index) => (
            <Button key={index} onClick={() => handleSelectReplacement(option)}>
              {option}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </section>
  );
}

export default RecipeCard;
