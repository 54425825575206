// Message.js
import React from 'react';
import './Message.css';
import Avatar from 'react-avatar';

function Message({ sender, content, isUser, isError }) {
  const messageClass = isUser ? 'message-user' : 'message-assistant';

  return (
    <div className={`message ${messageClass} ${isError ? 'error' : ''}`}>
      <Avatar
        className="message-avatar"
        name={sender}
        round={true}
        size="50"
        src={!isUser ? `${process.env.PUBLIC_URL}/chef.png` : null}
      />
      <p className={isError ? 'error-message' : ''}>{content}</p>
    </div>
  );
}

export default Message;
