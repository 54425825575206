import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { matchSorter } from 'match-sorter';
import './SearchBox.css';
import { trackEvent } from '../analytics';

function SearchBox({ query: initialQuery = '', popularSearchTerms = [] }) {
  const [query, setQuery] = useState(initialQuery);
  const navigate = useNavigate();

  const handleSubmit = (event, newValue) => {
    event.preventDefault();
    const searchQuery = newValue || query;
    if (!searchQuery) return;
    trackEvent('search_initiated', { search_query: searchQuery });
    setQuery(searchQuery);
    navigate(`/${searchQuery}`);
  };

  return (
    <div className="recipe-search-container">
      <Autocomplete
        sx={{ minWidth: '250px' }}
        freeSolo
        options={popularSearchTerms}
        value={query}
        onChange={handleSubmit}
        filterOptions={(options, params) => {
          const filtered = matchSorter(options, params.inputValue, { threshold: matchSorter.rankings.CONTAINS });
          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for a recipe"
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter" && e.target.value) {
                handleSubmit(e, query);
              }
            }}
            sx={{
              width: '100%'
            }}
          />
        )}
      />
    </div>
  );
}

export default SearchBox;
