import React from 'react';
import { Link } from 'react-router-dom';
import './RecommendedRecipes.css';

function RecommendedRecipes({ recipes }) {
    if (0 === recipes.length) {
        return null;
    }
    return (
        <div className="recommended-recipes">
            <h2>Recommended Recipes</h2>
            <div className="recipe-grid">
                {recipes.map((recipe, index) => (
                    <Link key={index} to={`/${recipe.name}`} className="recipe-item">
                        <div className="recipe-content">
                            <h3>{recipe.name}</h3>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default RecommendedRecipes;
